<template>
  <div class="auth-page d-flex">
    <div class="auth-page__content">
      <img v-if="logo" :src="require(`@/assets/images/${logo}`)" class="posiq-logo" alt="posiq-logo">

      <form @submit.prevent="login" v-if="!showResetPassword">
        <input-field id="username" v-model="username" type="text" class="mb-4" label="Användarnamn"></input-field>
        <input-field id="password" v-model="password" type="password" class="mb-4" label="Lösenord">
          <template #right-label><span class="action-small" @click="showResetPassword = true">Glömt lösenord?</span></template>
        </input-field>
        <app-button :loading="loading" class="btn--block btn--primary">Logga in</app-button>
      </form>

      <template v-else>
        <div class="mb-4 mt-2 note-text">
          En återställningslänk kommer att skickas till din e-post.
        </div>
        <validation-observer v-slot="{valid}">
          <validation-provider rules="required|email" v-slot="{invalid}">
            <input-field id="E-post" v-model="resetEmail" type="text" class="mb-4" label="E-post"></input-field>
            <app-button @click="sendResetEmail" :disabled="invalid" :loading="loading" class="btn--block btn--primary mb-3">Återställ lösenord</app-button>
          </validation-provider>
        </validation-observer>
        <app-button class="btn--block btn--transparent" @click="showResetPassword = false">Tillbaka</app-button>
      </template>
<!--      <button @click="$router.push('/register')" class="mt-4 btn btn&#45;&#45;block btn&#45;&#45;transparent">New customer?</button>-->
    </div>
  </div>
</template>

<script>
import auth from '@/http/auth'
import AppButton from "@/components/shared/AppButton";
import {storeTokensData} from "@/services/auth-service";
import {getThemePresets} from "@/theme/theme-maker";
import {warningTranslations} from "@/utils/error.helper";

export default {
  components: {AppButton},
  data() {
    return {
      loading: false,
      username: '',
      password: '',
      resetEmail: '',
      showResetPassword: false,
      logo: null
    }
  },
  methods: {
    async login() {
      try {
        this.loading = true;

        const response = await auth.login(this.username, this.password);
        storeTokensData(response);

        this.$store.commit('setUser', response.access_token);

        await this.$router.push('/');
      } catch (e) {
        this.$notify.error('Fel inloggningsuppgifter!');
      } finally {
        this.loading = false;
      }
    },
    async sendResetEmail() {
      try {
        await auth.sendResetEmail(this.resetEmail);
        this.$notify.success(warningTranslations.PASSWORD_RESET_SUCCESS)
      } catch (e) {
        this.$notify.error('E-posten finns inte!');
      }
    }
  },
  async created() {
    const theme = await getThemePresets()
    this.logo = theme.logo
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/style/scss/_auth-pages.scss";

.auth-page__content {
  width: 350px;
  text-align: center;
}
.posiq-logo {
  max-width: 300px;
  margin-bottom: 25px;
}
.note-text {
  text-align: center;
  color: var(--color-gray);
  font-size: 15px;
  font-weight: 500;
}
</style>
